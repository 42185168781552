import Error from 'next/error'

// NOTES:
//
// Unfortunately due to how the pages/[seller]/[catalog] route acts kind of as a
// "catch all" route, I'm not sure Next.js would ever detect a 404 on it's own.
//
// Currently, we need to ensure all pages that fetch data via params, detect and
// return 404 statusCode (via try/catch), in order for this page to be rendered,
// rather than 500.tsx (which is the default for any exception, even 404s, for
// serverless functions)

export default function NotFound() {
  // Opinionated: do not record an exception in Sentry for 404
  return <Error statusCode={404} />
}
